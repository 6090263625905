
import { computed, defineComponent, reactive, ref, warn } from "vue";

export default defineComponent({
  name: 'Fuel',
  setup() {

    const submitButton = ref<null | HTMLButtonElement>(null);
    const form = reactive({
      nopol: '',
      location: '',
      date: '',
      time: ''
    });

    const disabledDate = (time: Date) => {
      const d = new Date();
      d.setDate(d.getDate() - 1);

      return time.getTime() < d.getTime()
    }

    const onSubmitRegister = async values => {
      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

    }

    return {
      submitButton,
      onSubmitRegister,
      form,
      disabledDate
    }
  }
})
